import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { AuthStatements } from '../models/auth-statements';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthStatementsEntityService extends BaseEntityService<AuthStatements> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'authStatement', plural: 'authStatements', url: 'auth-statements' },
      routerParamsService
    );
    this.nameId = 'authStatementId';
  }
}
