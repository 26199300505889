import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { Allergy, PreviousAllergy } from '../models/previous-allergies';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreviousAllergiesEntityService extends BaseEntityService<PreviousAllergy> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'previousAllergy', plural: 'previousAllergies', url: 'previous-allergies' },
      routerParamsService
    );
    this.nameId = 'previousAllergyId';
  }

  getWithQueryAllergies(
    queryParams: any,
  ): Observable<Allergy[]> {
    return this.httpClient
      .get(`${ environment.apiUrl }/allergies`, { params: queryParams })
      .pipe(
        map((response: any) => response?.allergies || []),
      );
  }

  addAllergy(allergy: Allergy): Observable<Allergy> {
    const body = { allergy };
    return this.httpClient
      .post(`${ environment.apiUrl }/allergies`, body)
      .pipe(map((response: any) => response?.allergy));
  }

  createAllergy(name: string): Observable<Allergy> {
    const body: Allergy = {
      name,
    };
    return this.addAllergy(body);
  }
}
