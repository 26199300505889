import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Medication, PreviousMedication } from '../models/previous-medication';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EntityResponse } from '../models/entity-map';

@Injectable({
  providedIn: 'root',
})
export class PreviousMedicationsEntityService extends BaseEntityService<PreviousMedication> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'previousMedication',
        plural: 'previousMedications',
        url: 'previous-medications'
      },
      routerParamsService
    );
    this.nameId = 'previousMedicationId';
  }

  getWithQueryMedications(
    queryParams: any,
  ): Observable<Medication[]> {
    return this.httpClient
      .get(`${environment.apiUrl}/medications`, { params: queryParams })
      .pipe(
        map((response: any) => response?.medications || []),
      );
  }
}
